const Body = () => {
   return (
      <>
         <h1 className="animate-charcter">
            Bienvenido a Scorpion Freelancers 👋
         </h1>

         <h2>
            Somos un grupo creativo de personas que diseñan marcas y
            experiencias digitales influyentes.
         </h2>
         <section className="basic-content">
            <h3>🇬🇶 🇬🇶 Estamos en contrucción, visítanos muy pronto 🇬🇶 🇬🇶</h3>
         </section>
      </>
   );
};

export default Body;
