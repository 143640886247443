import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";

const AppLayout = () => {
   return (
      <>
         <Header />
         <Body />
         <Footer />
      </>
   );
};

export default AppLayout;
