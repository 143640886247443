import logo from "../img/nuestro_logo.png";

const Title = () => (
   <a href="/">
      <img className="logo" alt="our logo" src={logo} />
   </a>
);

const Header = () => {
   return (
      <div className="header">
         <Title />
         <nav className="nav-items">
            <ul>
               <li>🏠Home</li>
               <li>Servicios</li>
               <li>Portfolio</li>
               <li>Nosotros</li>
               <li>Blog</li>
               <li>Contacto</li>
            </ul>
         </nav>
      </div>
   );
};

export default Header;
<a href="/"></a>;
